<template>
    <div>
        <Navbar />
        <div class="hero" :style="{ backgroundImage: 'url(' + heroData[currentIndex].image + ')' }">
            <div class="hero-content">
                <h1>{{ heroData[currentIndex].title }}</h1>
                <p>{{ heroData[currentIndex].description }}</p>
                <button class="hero-button">Solicitar Servicio</button>
            </div>
            <div class="hero-navigation">
                <div class="arrow left-arrow" @click="prevImage"><i class="bi bi-arrow-left-circle"></i></div>
                <div class="arrow right-arrow" @click="nextImage"><i class="bi bi-arrow-right-circle"></i></div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import Navbar from '@/components/header/Navbar.vue';
import Footer from '@/components/footer/Footer.vue';

export default {
    name: 'HomePage',
    components: {
        Navbar,
        Footer
    },
    data() {
        return {
            heroData: [
                {
                    image: require('@/assets/hero/hero1.webp'),
                    title: 'Solución Moderna',
                    description: 'Innovación en cada reparación.'
                },
                {
                    image: require('@/assets/hero/hero2.webp'),
                    title: 'Calidad Garantizada',
                    description: 'Confiabilidad y eficiencia en todos nuestros servicios.'
                },
                {
                    image: require('@/assets/hero/hero3.webp'),
                    title: 'Reparación Avanzada',
                    description: 'El futuro de tus electrodomésticos.'
                },
            ],
            currentIndex: 0
        };
    },
    methods: {
        nextImage() {
            this.currentIndex = (this.currentIndex + 1) % this.heroData.length;
        },
        prevImage() {
            this.currentIndex = (this.currentIndex - 1 + this.heroData.length) % this.heroData.length;
        }
    },
    mounted() {
        this.autoSlide = setInterval(this.nextImage, 5000); // Cambia la imagen cada 5 segundos
    },
    beforeUnmount() {
        clearInterval(this.autoSlide);
    }
};
</script>


<style scoped>
.wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.content {
    flex: 1;
    padding: 20px;
}

/* Hero Styles */
.hero {
    position: relative;
    background-size: cover;
    background-position: center;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-image 1.5s ease-in-out;
}

.hero-content {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    color: #fff;
}

.hero-content h1 {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 10px;
}

.hero-content p {
    font-size: 1.5rem;
    margin-bottom: 20px;
}

.hero-button {
    background-color: #a50034;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.hero-button:hover {
    background-color: #8c0102;
}

.hero-navigation {
    position: absolute;
    width: 100%;
    top: 50%;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    transform: translateY(-50%);
}

.arrow {
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.arrow:hover {
    background-color: rgba(255, 255, 255, 1);
    transform: scale(1.1);
}

.arrow i {
    font-size: 2rem;
    color: #a50034;
}

.footer {
    background-color: #333;
    color: #fff;
    padding: 40px 0;
}

.footer-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 1200px;
    margin: auto;
    padding: 0 20px;
    flex-wrap: wrap;
}

.footer-about,
.footer-links,
.footer-contact,
.footer-app {
    width: 100%;
    max-width: 22%;
    margin-bottom: 20px;
}

.footer h3 {
    color: #ffc107;
    margin-bottom: 20px;
}

.footer-logo-container {
    background-color: #fff;
    padding: 10px;
    border-radius: 15px;
    display: inline-block;
    margin-bottom: 15px;
}

.footer-logo {
    width: 100px;
}

.footer-links ul {
    list-style: none;
    padding: 0;
}

.footer-links li {
    margin-bottom: 10px;
}

.footer-links li a {
    color: #fff;
    text-decoration: none;
}

.footer-links li a:hover {
    text-decoration: underline;
}

.footer-contact p {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.footer-contact i {
    margin-right: 10px;
    color: #ffc107;
}

.social-icons {
    display: flex;
    gap: 15px;
    margin-top: 15px;
}

.google-play {
    width: 150px;
    cursor: pointer;
    margin-top: 15px;
}

.footer-bottom {
    text-align: center;
    margin-top: 40px;
    padding-top: 20px;
    border-top: 1px solid #444;
}

@media (max-width: 768px) {
    .footer-container {
        flex-direction: column;
        align-items: center;
    }

    .footer-about,
    .footer-links,
    .footer-contact,
    .footer-app {
        max-width: 100%;
        text-align: center;
    }

    .hero-content h1 {
        font-size: 2rem;
    }

    .hero-content p {
        font-size: 1rem;
    }
}
</style>