<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App'
};
</script>

<style>
/* Custom scrollbar styling */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f8f9fa;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #a50034;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #8c0102;
}

/* Firefox scrollbar styling */
* {
  scrollbar-width: thin;
  scrollbar-color: #a50034 #f8f9fa;
}

</style>