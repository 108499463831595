<template>
    <div>
        <Navbar />
        <div class="about-section">
            <div class="about-content">
                <div class="about-text">
                    <h1>Sobre Nosotros</h1>
                    <p>En nuestra empresa, nos dedicamos a ofrecer servicios de reparación de electrodomésticos con un enfoque en la modernidad y la innovación. Nuestro equipo de expertos está comprometido en proporcionar soluciones eficientes y efectivas para mantener tus electrodomésticos funcionando como nuevos. Somos un servicio autorizado de reparación por LG.</p>
                    <p>Creemos en la importancia de la calidad y la satisfacción del cliente. Por eso, nos esforzamos por superar las expectativas en cada servicio que ofrecemos. Confía en nosotros para el cuidado de tus electrodomésticos.</p>
                </div>
                <div class="about-image">
                    <img src="@/assets/about/about-us.jpg" alt="About Us" />
                </div>
            </div>
            <div class="values-section">
                <div class="value-card">
                    <i class="bi bi-tools"></i>
                    <h3>Experiencia Profesional</h3>
                    <p>Más de 20 años en el sector de la reparación de electrodomésticos.</p>
                </div>
                <div class="value-card">
                    <i class="bi bi-shield-check"></i>
                    <h3>Servicio Autorizado</h3>
                    <p>Autorizados por LG para ofrecer servicios de reparación.</p>
                </div>
                <div class="value-card">
                    <i class="bi bi-people-fill"></i>
                    <h3>Equipo Calificado</h3>
                    <p>Técnicos certificados y capacitados constantemente.</p>
                </div>
                <div class="value-card">
                    <i class="bi bi-bullseye"></i>
                    <h3>Misión</h3>
                    <p>Ofrecer servicios de alta calidad para la reparación de electrodomésticos, garantizando la satisfacción y confianza de nuestros clientes.</p>
                </div>
                <div class="value-card">
                    <i class="bi bi-eye"></i>
                    <h3>Visión</h3>
                    <p>Ser líderes en el sector de reparación de electrodomésticos, reconocidos por nuestra innovación, calidad y excelencia en el servicio.</p>
                </div>
                <div class="value-card">
                    <i class="bi bi-heart-fill"></i>
                    <h3>Valores</h3>
                    <p>Compromiso, integridad, innovación y orientación al cliente.</p>
                </div>
            </div>
        </div>
        <div class="testimonials-section">
            <h2>Testimonios</h2>
            <div class="testimonials">
                <div class="testimonial-card">
                    <p>"Excelente servicio, muy profesionales y rápidos."</p>
                    <p class="author">- Pedro Sánchez</p>
                </div>
                <div class="testimonial-card">
                    <p>"Repararon mi lavadora en tiempo récord. Muy recomendados."</p>
                    <p class="author">- Lucía Fernández</p>
                </div>
                <div class="testimonial-card">
                    <p>"Atención al cliente excepcional y precios justos."</p>
                    <p class="author">- José Rodríguez</p>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import Navbar from '@/components/header/Navbar.vue';
import Footer from '@/components/footer/Footer.vue';

export default {
    name: 'AboutPage',
    components: {
        Navbar,
        Footer
    }
};
</script>

<style scoped>
.about-section {
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
}

.about-content {
    display: flex;
    flex-direction: row;
    gap: 40px;
    align-items: center;
    max-width: 1200px;
    width: 100%;
}

.about-text {
    flex: 1;
}

.about-image img {
    max-width: 100%;
    width: 400px;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.values-section {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    width: 100%;
    max-width: 1200px;
}

.value-card {
    background-color: #fff;
    border-radius: 15px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s ease;
    cursor: pointer;
}

.value-card:hover {
    transform: translateY(-10px);
}

.value-card i {
    font-size: 2rem;
    color: #a50034;
    margin-bottom: 10px;
}

.testimonials-section {
    padding: 40px;
    text-align: center;
    background-color: #f9f9f9;
    width: 100%;
}

.testimonials {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
}

.testimonial-card {
    background-color: #fff;
    border-radius: 15px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 350px;
    flex: 1;
    transition: transform 0.3s ease;
}

.testimonial-card:hover {
    transform: translateY(-10px);
}

.author {
    font-weight: bold;
    color: #a50034;
    margin-top: 10px;
}

@media (max-width: 768px) {
    .about-content {
        flex-direction: column;
        text-align: center;
    }
    
    .about-image img {
        width: 100%;
    }

    .value-card {
        padding: 15px;
    }

    .testimonials-section {
        padding: 20px;
    }
}
</style>