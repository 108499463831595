<template>
    <div class="location-container" @click="showAlert">
        <!-- Icono de localización -->
        <div class="location-icon">
            <i class="bi bi-geo-alt"></i>
        </div>
        <!-- Dirección que siempre se muestra -->
        <span class="user-address">{{ userAddress }}</span>
    </div>
</template>

<script>
import Swal from 'sweetalert2';

export default {
    name: 'AppLocation',
    data() {
        return {
            userAddress: 'Calle 165, Bogotá, Colombia',
        };
    },
    methods: {
        showAlert() {
            Swal.fire({
                title: 'Dirección completa',
                text: this.userAddress,
                icon: 'info',
                confirmButtonText: 'Cerrar',
                customClass: {
                    popup: 'swal-popup',
                    confirmButton: 'swal-confirm-button'
                }
            });
        },
    },
};
</script>

<style scoped>
.location-container {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    max-width: 250px;
    /* Limitar el ancho total */
}

.location-icon {
    font-size: 28px;
    color: #333;
}

.user-address {
    margin-left: 10px;
    font-size: 1rem;
    color: #333;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Estilos personalizados para SweetAlert2 */
.swal-popup {
    font-size: 1.1rem;
}

.swal-confirm-button {
    background-color: #a50034;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
}

.swal-confirm-button:hover {
    background-color: #8c0102;
}
</style>
