<template>
    <Navbar />

    <div class="contact-page">
        <div class="contact-content">
            <h1>Contáctanos</h1>
            <p>Nos encantaría saber de ti. Completa el siguiente formulario y nos pondremos en contacto contigo lo antes
                posible.</p>
            <div class="contact-wrapper">
                <div class="map-container">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3976.841354698346!2d-74.06364408467766!3d4.624335643231756!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e3f99b64f7f7cdd%3A0x7e5e8a6f4c4e4bfa!2sMySIT!5e0!3m2!1ses!2sco!4v1697482730211!5m2!1ses!2sco"
                        width="100%" height="300" style="border:0; border-radius: 10px;" allowfullscreen=""
                        loading="lazy"></iframe>
                </div>
                <form @submit.prevent="submitForm" class="contact-form">
                    <div class="form-group">
                        <label for="name">Nombre:</label>
                        <input type="text" id="name" v-model="name" required />
                    </div>
                    <div class="form-group">
                        <label for="email">Correo Electrónico:</label>
                        <input type="email" id="email" v-model="email" required />
                    </div>
                    <div class="form-group">
                        <label for="message">Mensaje:</label>
                        <textarea id="message" v-model="message" required></textarea>
                    </div>
                    <button type="submit" class="btn-submit">Enviar Mensaje</button>
                </form>
            </div>
        </div>
    </div>

    <Footer />
</template>

<script>
import Navbar from '@/components/header/Navbar.vue';
import Footer from '@/components/footer/Footer.vue';

export default {
    name: 'ContactPage',
    components: {
        Navbar,
        Footer
    },
    data() {
        return {
            name: '',
            email: '',
            message: ''
        };
    },
    methods: {
        submitForm() {
            alert(`Gracias, ${this.name}. Hemos recibido tu mensaje y nos pondremos en contacto pronto.`);
            this.name = '';
            this.email = '';
            this.message = '';
        }
    }
};
</script>

<style scoped>
.contact-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
    font-family: 'Poppins', sans-serif;
    background-color: #f7f7f7;
    border-radius: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.contact-content {
    text-align: center;
    padding: 20px;
}

.contact-content h1 {
    font-size: 2.5rem;
    color: #a50034;
    margin-bottom: 20px;
    font-weight: bold;
}

.contact-content p {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 40px;
}

.contact-wrapper {
    display: flex;
    gap: 40px;
    margin-top: 20px;
    flex-wrap: wrap;
    justify-content: center;
}

.map-container {
    flex: 1 1 400px;
    min-width: 300px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contact-form {
    flex: 1 1 400px;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.form-group {
    text-align: left;
}

label {
    font-weight: bold;
    color: #333;
}

input,
textarea {
    width: 100%;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 1rem;
    font-family: 'Poppins', sans-serif;
}

textarea {
    resize: vertical;
}

.btn-submit {
    padding: 15px;
    background-color: #a50034;
    color: #fff;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s;
}

.btn-submit:hover {
    background-color: #8c0102;
}

@media (max-width: 768px) {
    .contact-wrapper {
        flex-direction: column;
    }

    .contact-content h1 {
        font-size: 2rem;
    }

    .contact-content p {
        font-size: 1rem;
    }
}
</style>