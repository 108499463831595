<template>
    <div>
        <Navbar />
        <div class="catalog-hero">
            <h1>Catálogo de Trabajos Realizados</h1>
            <p>Conoce los proyectos y servicios que hemos realizado con calidad y dedicación.</p>
        </div>
        <div class="catalog-grid">
            <div class="catalog-card" v-for="project in projects" :key="project.id">
                <div class="image-container">
                    <img :src="project.imageBefore" alt="Before Image" class="project-image before-image" />
                    <img :src="project.imageAfter" alt="After Image" class="project-image after-image" />
                </div>
                <div class="catalog-card-content">
                    <h3>{{ project.name }}</h3>
                    <p>{{ project.description }}</p>
                    <button @click="viewGallery(project)" class="btn-view-more">Ver Galería</button>
                </div>
            </div>
        </div>
        <Footer />

        <!-- Modal para la galería -->
        <div v-if="showGalleryModal" class="modal-overlay" @click="closeGallery">
            <div class="gallery-modal" @click.stop>
                <h2>{{ selectedProject.name }}</h2>
                <div class="gallery-images">
                    <img v-for="(image, index) in selectedProject.gallery" :key="index" :src="image" class="gallery-image" />
                </div>
                <button @click="closeGallery" class="btn-close-modal">Cerrar</button>
            </div>
        </div>
    </div>
</template>

<script>
import Navbar from '@/components/header/Navbar.vue';
import Footer from '@/components/footer/Footer.vue';

export default {
    name: 'CatalogPage',
    components: {
        Navbar,
        Footer
    },
    data() {
        return {
            projects: [
                {
                    id: 1,
                    name: 'Mantenimiento de Lavadora LG',
                    description: 'Proceso de mantenimiento completo, incluyendo limpieza de tambor y ajustes de eficiencia.',
                    imageBefore: require('@/assets/logo.png'),
                    imageAfter: require('@/assets/logo.png'),
                    gallery: [
                        require('@/assets/logo.png'),
                        require('@/assets/logo.png'),
                        require('@/assets/logo.png')
                    ]
                },
                {
                    id: 2,
                    name: 'Reparación de Refrigerador Samsung',
                    description: 'Reparación de componentes eléctricos y ajuste del sistema No Frost.',
                    imageBefore: require('@/assets/logo.png'),
                    imageAfter: require('@/assets/logo.png'),
                    gallery: [
                        require('@/assets/logo.png'),
                        require('@/assets/logo.png'),
                        require('@/assets/logo.png')
                    ]
                },
                {
                    id: 3,
                    name: 'Instalación de Televisor en Pared',
                    description: 'Montaje de televisor con soporte reforzado y gestión de cables.',
                    imageBefore: require('@/assets/logo.png'),
                    imageAfter: require('@/assets/logo.png'),
                    gallery: [
                        require('@/assets/logo.png'),
                        require('@/assets/logo.png'),
                        require('@/assets/logo.png')
                    ]
                }
            ],
            selectedProject: null,
            showGalleryModal: false
        };
    },
    methods: {
        viewGallery(project) {
            this.selectedProject = project;
            this.showGalleryModal = true;
        },
        closeGallery() {
            this.showGalleryModal = false;
            this.selectedProject = null;
        }
    }
};
</script>

<style scoped>
.catalog-hero {
    background-color: #a50034;
    color: #fff;
    text-align: center;
    padding: 50px 20px;
    margin-bottom: 40px;
    border-radius: 10px;
}

.catalog-hero h1 {
    font-size: 3rem;
    font-weight: bold;
}

.catalog-hero p {
    font-size: 1.2rem;
    margin-top: 10px;
}

.catalog-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 30px;
    padding: 20px;
}

.catalog-card {
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    overflow: hidden;
    position: relative;
}

.catalog-card:hover {
    transform: translateY(-10px);
}

.image-container {
    position: relative;
    height: 200px;
    overflow: hidden;
}

.project-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    transition: opacity 0.5s ease-in-out;
    position: absolute;
    top: 0;
    left: 0;
}

.before-image {
    opacity: 1;
    z-index: 0;
}

.after-image {
    opacity: 0;
    z-index: 1;
}

.catalog-card:hover .after-image {
    opacity: 1;
}

.catalog-card-content {
    padding: 20px;
    text-align: center;
}

.catalog-card-content h3 {
    font-size: 1.5rem;
    color: #333;
}

.catalog-card-content p {
    font-size: 1rem;
    color: #555;
    margin-bottom: 10px;
}

.btn-view-more {
    background-color: #a50034;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 30px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.btn-view-more:hover {
    background-color: #8c0102;
}

/* Modal Styles */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1200;
}

.gallery-modal {
    background-color: #fff;
    padding: 20px;
    border-radius: 15px;
    width: 80%;
    max-width: 600px;
    text-align: center;
}

.gallery-images {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-top: 20px;
    justify-content: center;
}

.gallery-image {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 10px;
    transition: transform 0.3s ease;
}

.gallery-image:hover {
    transform: scale(1.1);
}

.btn-close-modal {
    margin-top: 20px;
    background-color: #a50034;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 30px;
    cursor: pointer;
}

.btn-close-modal:hover {
    background-color: #8c0102;
}

@media (max-width: 768px) {
    .catalog-hero h1 {
        font-size: 2rem;
    }

    .catalog-hero p {
        font-size: 1rem;
    }
}
</style>
