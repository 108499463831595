<template>
    <div>
        <Toolbar v-show="showToolbar" />
        <nav class="navbar" :class="{ 'navbar-fixed': window?.scrollY > 0, 'navbar-transparent': window?.scrollY === 0 }">
            <div class="navbar-left">
                <img src="@/assets/logo.png" alt="Logo" class="logo" />
                <!-- Location for desktop -->
                <div class="location-info d-none d-md-flex">
                    <Location />
                </div>
                <!-- Botón para el menú móvil -->
                <div class="navbar-toggler d-md-none" @click="toggleSidebar">
                    <i class="bi bi-list"></i>
                </div>
            </div>

            <!-- Contenedor para el menú de navegación y el botón de ingreso para escritorio -->
            <div class="navbar-center d-none d-md-flex">
                <div class="navbar-menu">
                    <router-link to="/" class="nav-item">Inicio</router-link>
                    <router-link to="/about" class="nav-item">Nosotros</router-link>
                    <router-link to="/services" class="nav-item">Servicios</router-link>
                    <router-link to="/catalog" class="nav-item">Catálogo</router-link>
                    <router-link to="/contact" class="nav-item">Contáctanos</router-link>
                </div>
                <a href="https://portal.tscenter.com.co/" class="btn btn-outline">Acceso Clientes</a>
            </div>

            <!-- Sidebar -->
            <Sidebar :sidebarOpen="sidebarOpen" @close-sidebar="toggleSidebar" />
        </nav>
        <div class="navbar-spacer" v-show="showToolbar"></div>
    </div>
</template>

<script>
import Toolbar from './Toolbar.vue';
import Location from './Location.vue';
import Sidebar from './Sidebar.vue';

export default {
    name: 'AppNavbar',
    components: {
        Toolbar,
        Location,
        Sidebar,
    },
    data() {
        return {
            sidebarOpen: false,
            showToolbar: true,
        };
    },
    methods: {
        toggleSidebar() {
            this.sidebarOpen = !this.sidebarOpen;
        },
        handleScroll() {
            if (typeof window !== 'undefined') {
                this.showToolbar = window.scrollY === 0;
                this.updateNavbarClasses();
            }
        },
        handleRouteChange() {
            if (typeof window !== 'undefined') {
                this.showToolbar = window.scrollY === 0;
                this.updateNavbarClasses();
            }
        },
        updateNavbarClasses() {
            const navbar = document.querySelector('.navbar');
            if (window.scrollY > 0) {
                navbar.classList.remove('navbar-transparent');
                navbar.classList.add('navbar-fixed');
            } else {
                navbar.classList.add('navbar-transparent');
                navbar.classList.remove('navbar-fixed');
            }
        },
    },
    mounted() {
        if (typeof window !== 'undefined') {
            window.addEventListener('scroll', this.handleScroll);
            this.$router.afterEach(() => {
                window.scrollTo(0, 0);
                this.handleRouteChange();
            });
            // Llamar al método para aplicar correctamente las clases al cargar la página
            this.updateNavbarClasses();
        }
    },
    beforeUnmount() {
        if (typeof window !== 'undefined') {
            window.removeEventListener('scroll', this.handleScroll);
        }
    },
};
</script>

<style scoped>
/* Estilos del Navbar */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    padding: 15px 30px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    position: fixed;
    z-index: 1100;
    transition: top 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease;
}

.navbar-fixed {
    top: 0;
    background-color: #ffffff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.navbar-transparent {
    background-color: transparent;
    box-shadow: none;
    border-bottom: none;
}

/* Espaciador para el Navbar fijo */
.navbar-spacer {
    height: 92px; /* Ajusta la altura para que coincida con el espacio del Toolbar y Navbar */
}

/* Alineación izquierda: Logo y localización */
.navbar-left {
    display: flex;
    align-items: center;
    gap: 20px;
}

/* Contenedor del menú y botón a la derecha (solo escritorio) */
.navbar-center {
    display: flex;
    align-items: center;
    margin-left: auto;
    gap: 20px;
}

/* Menú de navegación */
.navbar-menu {
    display: flex;
    gap: 15px;
}

/* Estilos del logo */
.logo {
    height: 60px;
    transition: transform 0.3s ease;
}

.logo:hover {
    transform: scale(1.1);
}

/* Estilos para la localización en desktop */
.location-info {
    display: flex;
    align-items: center;
    max-width: 250px;
    font-size: 0.9rem;
    color: #666;
}

/* Estilos del menú de navegación */
.nav-item {
    text-decoration: none;
    color: #333;
    font-weight: bold;
    font-size: 1rem;
    transition: color 0.3s, transform 0.3s;
    position: relative;
}

.nav-item:hover {
    color: #8C0102;
    transform: translateY(-2px);
}

.nav-item::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -5px;
    width: 0;
    height: 2px;
    background-color: #8C0102;
    transition: width 0.3s;
}

.nav-item:hover::after {
    width: 100%;
}

/* Estilos del botón Ingresar */
.btn {
    padding: 10px 25px;
    border-radius: 25px;
    font-size: 1rem;
    border: 2px solid #8C0102;
    color: #8C0102;
    background-color: transparent;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
}

.btn:hover {
    background-color: #8C0102;
    color: #ffffff;
    box-shadow: 0 4px 10px rgba(255, 87, 34, 0.3);
}

/* Botón de menú móvil */
.navbar-toggler {
    font-size: 2rem;
    color: #8C0102;
    cursor: pointer;
    margin-left: auto;
    align-self: center;
}

/* Ajustes para pantallas medianas y móviles */
@media (max-width: 1280px) {
    .navbar {
        padding: 10px 20px;
    }
}

@media (max-width: 768px) {
    .navbar {
        padding: 10px 15px;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 20px; /* Añadir margen inferior para evitar que el contenido se superponga al navbar fijo */
    }

    .navbar-left {
        width: auto;
        justify-content: space-between;
        align-items: center;
        flex-grow: 1;
    }

    .logo {
        height: 50px;
    }

    /* Ocultar menú de navegación y botón Ingresar en móviles */
    .navbar-center {
        display: none;
    }

    /* Alinear el menú móvil al final */
    .navbar-toggler {
        margin-left: 0;
        align-self: center;
    }

    /* Ajustar el espaciador para móviles */
    .navbar-spacer {
        height: 92px;
        display: block;
    }
}
</style>