<!-- Footer Component -->
<template>
    <footer class="footer">
        <div class="footer-container">
            <div class="footer-about">
                <h3>Sobre Nosotros</h3>
                <div class="footer-logo-container">
                    <img src="@/assets/logo.png" alt="Logo" class="footer-logo" />
                </div>
                <p>Ofrecemos servicios de reparación de electrodomésticos con un enfoque en la modernidad y la
                    innovación. Somos un servicio autorizado de reparación por LG.</p>
            </div>
            <div class="footer-links">
                <h3>Enlaces Útiles</h3>
                <ul>
                    <li><router-link to="/">Inicio</router-link></li>
                    <li><router-link to="/about">Nosotros</router-link></li>
                    <li><router-link to="/services">Servicios</router-link></li>
                    <li><router-link to="/catalog">Catálogo</router-link></li>
                    <li><router-link to="/contact">Contáctanos</router-link></li>
                </ul>
            </div>
            <div class="footer-contact">
                <h3>Contáctanos</h3>
                <p><i class="bi bi-telephone-fill"></i> +57 314 7368414</p>
                <p><i class="bi bi-envelope-fill"></i> gerencia@tscenter.com.co</p>
                <div class="social-icons">
                    <i class="bi bi-facebook"></i>
                    <i class="bi bi-instagram"></i>
                    <i class="bi bi-tiktok"></i>
                </div>
            </div>
            <div class="footer-app">
                <h3>Descarga Nuestra App</h3>
                <img src="@/assets/google-play.webp" alt="Google Play" class="google-play" />
            </div>
        </div>
        <div class="footer-bottom">
            <p>© 2024 Technical Support Center. Todos los derechos reservados.</p>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'AppFooter'
};
</script>

<style scoped>
.footer {
    background-color: #333;
    color: #fff;
    padding: 40px 0;
}

.footer-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 1200px;
    margin: auto;
    padding: 0 20px;
    flex-wrap: wrap;
}

.footer-about,
.footer-links,
.footer-contact,
.footer-app {
    width: 100%;
    max-width: 22%;
    margin-bottom: 20px;
}

.footer h3 {
    color: #ffc107;
    margin-bottom: 20px;
}

.footer-logo-container {
    background-color: #fff;
    padding: 10px;
    border-radius: 15px;
    display: inline-block;
    margin-bottom: 15px;
}

.footer-logo {
    width: 100px;
}

.footer-links ul {
    list-style: none;
    padding: 0;
}

.footer-links li {
    margin-bottom: 10px;
}

.footer-links li a {
    color: #fff;
    text-decoration: none;
}

.footer-links li a:hover {
    text-decoration: underline;
}

.footer-contact p {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.footer-contact i {
    margin-right: 10px;
    color: #ffc107;
}

.social-icons {
    display: flex;
    gap: 15px;
    margin-top: 15px;
}

.google-play {
    width: 150px;
    cursor: pointer;
    margin-top: 15px;
}

.footer-bottom {
    text-align: center;
    margin-top: 40px;
    padding-top: 20px;
    border-top: 1px solid #444;
}

@media (max-width: 768px) {
    .footer-container {
        flex-direction: column;
        align-items: center;
    }

    .footer-about,
    .footer-links,
    .footer-contact,
    .footer-app {
        max-width: 100%;
        text-align: center;
    }

    .social-icons {
        justify-content: center;
    }
}
</style>
