<template>
    <div class="toolbar">
        <div class="toolbar-left">
            <img src="@/assets/logo-lg.svg" alt="LG Logo" class="logo-toolbar" />
            <span>Servicio Autorizado LG</span>
            <span>|</span>
            <a href="https://app.tscenter.com.co/">Acceso Administrativo</a>
            <span>|</span>
            <a href="#">FAQ</a>
            <span>|</span>
            <a href="#">Ayuda</a>
        </div>
        <div class="toolbar-right">
            <a href="#" target="_blank"><i class="bi bi-facebook"></i></a>
            <a href="#" target="_blank"><i class="bi bi-instagram"></i></a>
            <a href="#" target="_blank"><i class="bi bi-tiktok"></i></a>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AppToolbar',
};
</script>

<style scoped>
/* Estilos del toolbar */
.toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #8C0102;
    color: white;
    padding: 3px 15px;
    font-size: 0.8rem;
    height: 40px;
}

/* Ocultar el toolbar en dispositivos móviles */
@media (max-width: 768px) {
    .toolbar {
        display: none;
    }
}

.logo-toolbar {
    height: 18px;
    margin-right: 8px;
    vertical-align: middle;
}

.toolbar-left {
    display: flex;
    align-items: center;
}

.toolbar-left span {
    margin: 0 8px;
}

.toolbar-left a {
    color: white;
    text-decoration: none;
    font-weight: bold;
}

.toolbar-left a:hover {
    text-decoration: underline;
}

.toolbar-right a {
    color: white;
    margin-left: 10px;
    font-size: 1rem;
}

.toolbar-right a:hover {
    color: #ccc;
}
</style>