<template>
    <div>
        <Navbar />
        <div class="services-section">
            <h2>Nuestros Servicios</h2>
            <div class="services-container">
                <div class="service-card" v-for="service in services" :key="service.id">
                    <img :src="service.image" :alt="service.title" class="service-image" />
                    <h3>{{ service.title }}</h3>
                    <p>{{ service.description }}</p>
                    <button class="service-button">Solicitar Servicio</button>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import Navbar from '@/components/header/Navbar.vue';
import Footer from '@/components/footer/Footer.vue';

export default {
    name: 'ServicesPage',
    components: {
        Navbar,
        Footer
    },
    data() {
    return {
        services: [
            {
                id: 1,
                title: 'Reparación de Neveras y Refrigeradores',
                description: 'Soluciones expertas para todos los problemas de refrigeración.',
                image: require('@/assets/services/fridge.webp')
            },
            {
                id: 2,
                title: 'Mantenimiento de Lavadoras',
                description: 'Servicio técnico especializado para asegurar el funcionamiento adecuado.',
                image: require('@/assets/services/washer.webp')
            },
            {
                id: 3,
                title: 'Arreglo de Secadoras',
                description: 'Reparación y mantenimiento de secadoras para un secado eficiente y rápido.',
                image: require('@/assets/services/dryer.webp')
            },
            {
                id: 4,
                title: 'Reparación de Televisores',
                description: 'Diagnóstico y reparación de televisores para una experiencia de visualización óptima.',
                image: require('@/assets/services/tv.webp')
            },
            {
                id: 5,
                title: 'Reparación de Microondas',
                description: 'Soluciones rápidas y efectivas para asegurar el funcionamiento adecuado de tus microondas.',
                image: require('@/assets/services/microwave.webp')
            },
            {
                id: 6,
                title: 'Mantenimiento de Estufas',
                description: 'Reparación y mantenimiento de estufas de gas y eléctricas. Servicio seguro y confiable.',
                image: require('@/assets/services/stove.webp')
            },
            {
                id: 7,
                title: 'Servicio Técnico de Lavajillas',
                description: 'Reparación profesional para mantener tus lavajillas funcionando como nuevos.',
                image: require('@/assets/services/dishwasher.webp')
            },
            {
                id: 8,
                title: 'Reparación de Equipos de Sonido',
                description: 'Mantenimiento y reparación de sistemas de sonido para una experiencia auditiva perfecta.',
                image: require('@/assets/services/stereo.webp')
            }
        ]
    };
}

};
</script>

<style scoped>
.services-section {
    padding: 40px;
    text-align: center;
}

.services-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

.service-card {
    background-color: #fff;
    border-radius: 15px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s ease;
}

.service-card:hover {
    transform: translateY(-10px);
}

.service-image {
    max-width: 100%;
    height: auto;
    border-radius: 15px;
    margin-bottom: 20px;
}

.service-button {
    padding: 10px 20px;
    border-radius: 30px;
    font-size: 1rem;
    border: 2px solid #a50034;
    color: #fff;
    background-color: #a50034;
    cursor: pointer;
    transition: background-color 0.3s;
}

.service-button:hover {
    background-color: #8c0102;
}

@media (max-width: 768px) {
    .services-section {
        padding: 20px;
    }
}
</style>