<template>
    <div>
        <!-- Fondo oscuro para detectar clics fuera del sidebar -->
        <div v-if="sidebarOpen" class="sidebar-backdrop" @click="closeSidebar"></div>

        <!-- Sidebar móvil -->
        <div class="sidebar" :class="{ 'sidebar-open': sidebarOpen }">
            <div class="close-btn" @click="closeSidebar">
                <i class="bi bi-x-lg"></i>
            </div>

            <!-- Dirección del usuario en la parte superior del sidebar -->
            <div class="user-address-sidebar">
                <Location />
            </div>

            <div class="sidebar-content">
                <router-link to="/" class="nav-item" @click="closeSidebar">Inicio</router-link>
                <router-link to="/about" class="nav-item" @click="closeSidebar">Nosotros</router-link>
                <router-link to="/services" class="nav-item" @click="closeSidebar">Servicios</router-link>
                <router-link to="/catalog" class="nav-item" @click="closeSidebar">Catálogo</router-link>
                <router-link to="/contact" class="nav-item" @click="closeSidebar">Contáctanos</router-link>
                <a href="https://portal.tscenter.com.co/" class="btn btn-outline">Acceso Clientes</a>
            </div>
        </div>
    </div>
</template>

<script>
import Location from './Location.vue';
export default {
    name: 'AppSidebar',
    components: {
        Location,  // Registering Location component
    },
    props: {
        sidebarOpen: {
            type: Boolean,
            required: true,
        },
        userAddress: {
            type: String,
            default: '',
        },
    },
    methods: {
        closeSidebar() {
            this.$emit('close-sidebar');
        },
    },
};
</script>

<style scoped>
/* Estilos del Sidebar */
.sidebar {
    position: fixed;
    top: 0;
    right: -100%;
    height: 100%;
    width: 300px;
    background-color: #ffffff;
    transition: right 0.4s cubic-bezier(0.25, 1, 0.5, 1);
    z-index: 1001;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: -4px 0 10px rgba(0, 0, 0, 0.3);
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    opacity: 1 !important;
}

.sidebar-open {
    right: 0;
}

/* Mostrar la dirección en la parte superior del sidebar */
.user-address-sidebar {
    font-size: 1rem;
    color: #333;
    padding: 20px;
    text-align: center;
    background-color: #f7f7f7;
    width: 100%;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.sidebar-content {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 30px;
    width: 100%;
}

.sidebar-content .nav-item {
    color: #333;
    margin-bottom: 20px;
    font-size: 1.4rem;
    font-weight: 600;
    transition: color 0.3s, transform 0.3s;
}

.sidebar-content .nav-item:hover {
    color: #8C0102;
    transform: translateX(10px);
}

.sidebar-content .btn-outline {
    width: 90%;
    margin: 15px auto;
    padding: 10px;
    border-radius: 25px;
    border: 2px solid #8C0102;
    color: #8C0102;
    background-color: transparent;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.sidebar-content .btn-outline:hover {
    background-color: #8C0102;
    color: #ffffff;
    box-shadow: 0 4px 10px rgba(255, 87, 34, 0.3);
}

.close-btn {
    align-self: flex-end;
    padding: 15px;
    font-size: 1.8rem;
    cursor: pointer;
    color: #8C0102;
    transition: transform 0.3s;
}

.close-btn:hover {
    transform: rotate(90deg);
}

/* Fondo oscuro para cerrar el sidebar al hacer clic fuera */
.sidebar-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1000;
    transition: opacity 0.3s ease;
}
</style>